import React from 'react';

import './Header.css';

import InfoImage from '../InfoImage';

import backgroundIMG from '../../assets/images/background.jpg';
import logo from '../../assets/images/logo.png';

function Header(){
    return(
        <div className="container-fluid p-0">
            <img src={backgroundIMG} className='col-xs-12 col-md-7 p-0' alt="background_img"></img>
            <img src={logo} alt="logo" className="logo"></img>
            <div className="col-xs-12 col-md-5">
                <br/>
                <h1 className="text-center p-1">Luxueux 4 pièces vue lac et glacier</h1>

                <hr />

                <div className="d-flex justify-content-around">
                    <InfoImage name="maison" line1="Superficie" line2="78 m²"/>
                    <InfoImage name="group" line1="Couchages" line2="8"/>
                    <InfoImage name="lit" line1="Chambres" line2="3"/>
                    <InfoImage name="douche" line1="Douches" line2="2"/>
                </div>

                <hr />

                <p className="motDuProprio">L'appartement "Solyel de la Meyjoun", du patois Tignard "Soleil dans la maison",
                est situé dans la Résidence de la Combe Folle à Tignes le Lac, au coeur de l'Espace Killy. 
                Rénové au cours de l'été 2016 et classé 4 étoiles "Meublé de Tourisme", 
                ce luxeux appartement orienté sud offre une vue imprenable sur le lac de Tignes et sur le Glacier de la Grande Motte.</p>
            </div>
        </div>

    );
}

export default Header;