import React from 'react';

import Slider from './Slider/Slider';

import './Carousel.css';


import Image1 from '../../assets/images/carousel/1.jpg';
import Image2 from '../../assets/images/carousel/2.jpg';
import Image3 from '../../assets/images/carousel/3.jpg';
import Image4 from '../../assets/images/carousel/4.jpg';
import Image5 from '../../assets/images/carousel/5.jpg';
import Image6 from '../../assets/images/carousel/6.jpg';
import Image7 from '../../assets/images/carousel/7.jpg';
import Image8 from '../../assets/images/carousel/8.jpg';
import Image9 from '../../assets/images/carousel/9.jpg';
import Image10 from '../../assets/images/carousel/10.jpg';

import Image11 from '../../assets/images/carousel/11.jpg';
import Image12 from '../../assets/images/carousel/12.jpg';
import Image13 from '../../assets/images/carousel/13.jpg';
import Image14 from '../../assets/images/carousel/14.jpg';
import Image15 from '../../assets/images/carousel/15.jpg';
import Image16 from '../../assets/images/carousel/16.jpg';
import Image17 from '../../assets/images/carousel/17.jpg';
import Image18 from '../../assets/images/carousel/18.jpg';
import Image19 from '../../assets/images/carousel/19.jpg';
import Image20 from '../../assets/images/carousel/20.jpg';

import Image21 from '../../assets/images/carousel/21.jpg';
import Image22 from '../../assets/images/carousel/22.jpg';

const images = [Image1, Image2, Image3, Image4, Image5, Image6, Image7, Image8, Image9, Image10, Image11, Image12, Image13, Image14, Image15, Image16, Image17, Image18, Image19, Image20, Image21, Image22];



function Galerie() {
  return(
    <div className="container-fluid">
      <h2 className="text-center">Galerie</h2>
      <br />
      <Slider
        options={{
          autoPlay: 4000,
          pauseAutoPlayOnHover: true,
          wrapAround: true,
          fullscreen: true,
          adaptiveHeight: true,
        }}
      >
        {images.map((image, index) => (
          <div className="carousel-cell" key={index}>
            <img src={image} alt="" className='image'/>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default Galerie;