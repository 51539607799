import React from 'react';
import './App.css';

import Header from '../Header';
import Description from '../Description';
import About from '../About';
import Carousel from '../Carousel';
import Localisation from '../Localisation';
import Tarif from '../Tarif';
import Calendar from '../Calendar';
import Contact from '../Contact';
import Mail from '../Mail';

function Divider(){
  return(
    <div>
        <br />
        <hr />
    </div>
  );
}

function App() {
  return (
    <div className="App">
        <Header />

        <Divider />

        <Description />

        <Divider />

        <About />

        <Divider />

        <Carousel />

        <Divider />

        <Localisation />

        <Divider />

        <Tarif />

        <Divider />

        <Calendar />

        <Divider />

        <Mail />

        <br />
        <br />
        <br />



    </div>
  );
}

export default App;
