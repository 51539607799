import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './bootstrap/bootstrap.css';
import App from './components/App/';
import PageNotFound from './components/PageNotFound';

import {
    BrowserRouter as Router,
    Switch,
    Route
} from 'react-router-dom';

function Root() {
    return(
        <Router>
            <Switch>
                <Route exact path='/' component={App} />
                <Route component={PageNotFound} status={404}/>
            </Switch>
        </Router>
    );
}

ReactDOM.render(<Root />, document.getElementById('root'));
