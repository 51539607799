import React from 'react';

import './InfoImage.css';

function InfoImage(props) {
    return(
        <div className="infoGroup text-center">
            <img src={require(`../../assets/images/${props.name}.png`)} alt={props.name} className="infoImage"/>
            <p className="infoText mb-0">{props.line1}</p>
            <p className="infoText">{props.line2}</p>
        </div>
    );
}

export default InfoImage;
