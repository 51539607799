import React, { Component } from 'react';

import './Contact.css';
import './Utils.css';


class Contact extends Component {

	constructor() {
		super();
		this.state = {
			name: '',
			vName: true,
			email: '',
			vEmail: true,
			message: '',
			vMessage: true,
			child: '',
			vChild: true,
			parent: '',
			vParent: true,

			in: '',
			vIn: true,
			out: '',
			vOut: true,
			height: '100px'
		}
	}

	verifyForm() {
		var valid = true;
		if (/[A-zÀ-ú]+/.test(this.state.name)) {
			this.setState({vName: true});
		}else{
			this.setState({vName: false});
			valid = false;
		}
		
		if (/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-||_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+([a-z]+|\d|-|\.{0,1}|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])?([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/.test(this.state.email)) {
			this.setState({vEmail: true});
		}else{
			this.setState({vEmail: false});
			valid = false;
		}

		if (this.state.message.length > 0) {
			this.setState({vMessage: true});
		}else{
			this.setState({vMessage: false});
			valid = false;
		}

		if (!isNaN(this.state.child)) {
			this.setState({vChild: true});
		}else{
			this.setState({vChild: false});
			valid = false;
		}

		if (!isNaN(this.state.parent)) {
			this.setState({vParent: true});
		}else{
			this.setState({vParent: false});
			valid = false;
		}

		return valid;
	}

	submitForm(e) {
		e.preventDefault();
		if(this.verifyForm()) {
			fetch(`https://solyeldelameyjoun.fr/SolyelPHP/api?datarequested=mail&name=${this.state.name}&mail=${this.state.email}&message=${this.state.message}&adult=${this.state.parent}&child=${this.state.child}&in=${this.state.in}&out=${this.state.out}`)
			.then(() => {
				this.setState({
					name: '',
					email: '',
					message: '',
					child: '',
					parent: ''
				});
			});
		}
	}
    
    render() {
        return(
			<div className="container-contact100">
				<div className="wrap-contact100">
					<h2 className="text-center">Contact</h2>
					<br />
					<br />
					<form className="contact100-form validate-form">

						

						<div className={'wrap-input100 rs1-wrap-input100 validate-input' + (!this.state.vName ? ' alert-validate' : '')} data-validate='Le champs "Nom" est obligatoire'>
							<span className="label-input100 myspan">Nom</span>
							<input className="input100" type="text" name="name" placeholder="Votre nom" autoComplete="off" value={this.state.name} onChange={(e) => this.setState({name: e.target.value})}/>
							<span className="focus-input100"></span>
						</div>

						<div className={'wrap-input100 rs1-wrap-input100 validate-input' + (!this.state.vEmail ? ' alert-validate' : '')} data-validate = "Un email valide est requis: ex@abc.xyz">
							<span className="label-input100 myspan">Email</span>
							<input className="input100" type="text" name="email" placeholder="Votre email" autoComplete="off" value={this.state.email} onChange={(e) => this.setState({email: e.target.value})}/>
							<span className="focus-input100"></span>
						</div>

						<div className={'wrap-input100 rs1-wrap-input100 validate-input' + (!this.state.vIn ? ' alert-validate' : '')} data-validate='Le champs "Arrivée" est obligatoire'>
							<span className="label-input100 myspan">Arrivée</span>
							<input className="input100" type="date" name="parent" placeholder="Date d'arrivée" autoComplete="off" value={this.state.in} onChange={(e) => this.setState({in: e.target.value})}/>
							<span className="focus-input100"></span>
						</div>

						<div className={'wrap-input100 rs1-wrap-input100 validate-input' + (!this.state.vOut ? ' alert-validate' : '')} data-validate = 'Le champs "Départ" est obligatoire'>
							<span className="label-input100 myspan">Départ</span>
							<input className="input100" type="date" name="child" placeholder="Date de départ" autoComplete="off" value={this.state.out} onChange={(e) => this.setState({out: e.target.value})}/>
							<span className="focus-input100"></span>
						</div>

						<div className={'wrap-input100 rs1-wrap-input100 validate-input' + (!this.state.vParent ? ' alert-validate' : '')} data-validate='Le champs "Adulte(s)" est obligatoire'>
							<span className="label-input100 myspan">Adulte(s)</span>
							<input className="input100" type="number" name="parent" placeholder="Nombre d'adulte(s)" autoComplete="off" value={this.state.parent} onChange={(e) => this.setState({parent: e.target.value})}/>
							<span className="focus-input100"></span>
						</div>

						<div className={'wrap-input100 rs1-wrap-input100 validate-input' + (!this.state.vChild ? ' alert-validate' : '')} data-validate = 'Le champs Enfant(s)" est obligatoire'>
							<span className="label-input100 myspan">Enfant(s)</span>
							<input className="input100" type="number" name="child" placeholder="Nombre d'enfant(s)" autoComplete="off" value={this.state.child} onChange={(e) => this.setState({child: e.target.value})}/>
							<span className="focus-input100"></span>
						</div>

						

						<div className={'wrap-input100 validate-input' + (!this.state.vMessage ? ' alert-validate' : '')} data-validate='Le champs "Message" est obligatoire'>
							<span className="label-input100 myspan">Message</span>
							<textarea className="input100 textzone" name="message" placeholder="Votre message" style={{"height": this.state.height}} onKeyUp={(el) => this.setState({height: document.querySelector('.textzone').scrollHeight + 'px'})} value={this.state.message} onChange={(e) => this.setState({message: e.target.value})}></textarea>
							<span className="focus-input100"></span>
						</div>

						<div className="container-contact100-form-btn">
							<button className="contact100-form-btn" onClick={ (e) => this.submitForm(e) }>
								<span className='myspan'>
									Envoyé
									<i className="fa fa-long-arrow-right m-l-7" aria-hidden="true"></i>
								</span>
							</button>
						</div>
					</form>
				</div>
			</div>
        );
    }
}
export default Contact;