import React from 'react';

import './About.css';

function About() {
    return(
        <div className="container-fluid">
            <h2 className="text-center">À propos de nous</h2>
            <br />
            <p className="aboutText">Amoureux de Tignes, 
            nous avons acheté et rénové cet appartement avec pour objectif de pouvoir réunir 
            comfortablement la famille avec un vaste espace commun et les espaces nuit à l'écart.
             La grande baie vitrée du séjour permet de bénéficier du panorama exceptionnel. 
             Un téléski permet un accès aux pistes skis aux pieds, depuis la résidence.</p>
        </div>
    )
}

export default About;