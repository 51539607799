import React from 'react';

import './Tarif.css'


class Tarif extends React.Component {
	state = {
		data: [{"periode": null,"price": null}],
	};

	componentDidMount() {
		fetch('https://solyeldelameyjoun.fr/SolyelPHP/api?datarequested=prices')
		.then(response => response.json())
		.then(data => this.setState({
          data: data,
        }));
	}
	render(){
		return(
			<div className="container-fluid">
				<h2 className="text-center">Tarifs</h2>
				<br />
				<table className="table-fill">

					<thead>

						<tr>
							<th className="text-left">Période</th>
							<th className="text-left">Tarif</th>
						</tr>

					</thead>

					<tbody className="table-hover">
						{this.state.data.map((line, index) => {
							return(
								<tr key={index}>
									<td className="text-left">{line.periode}</td>
									<td className="text-left">{line.price}</td>
								</tr>
							)
						})}
					</tbody>

				</table>
			</div>
		);
	}
}

export default Tarif;