import React from 'react'

function Mail() {
    return (
        <div className='container-fluid'>
            <h2 className="text-center">Nous contacter</h2>
            <br />
            <p className="text-center motDuProprio">Pour toute information ou demande de réservation, envoyez-nous un mail à <a className="motDuProprio" href="mailto: contact@solyeldelameyjoun.fr"><u>contact@solyeldelameyjoun.fr</u></a></p>

        </div>
    )
}

export default Mail