import React, { Component } from 'react';
import './Calendar.css';


const months = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];


class Calendar extends Component {
    
    constructor(props){
        super(props);
        this.state = {
            currentMonth: new Date().getMonth(),
            currentYear: new Date().getFullYear(),
            calendar: []
        };
    }

    componentDidMount() {
		fetch('https://solyeldelameyjoun.fr/SolyelPHP/api?datarequested=calendar')
		.then(response => response.json())
		.then(data => this.setState({
            calendar: data,
        }));
	}

    generateDays() {
        var daysInMonth = new Date(this.state.currentYear, this.state.currentMonth+1, 0).getDate();
        let days = [];
        for (let i = 1; i <= daysInMonth; i++) {
            let day = new Date(this.state.currentYear, this.state.currentMonth, i).getDay();
            let spacing = 0;
            if(i === 1){
                if (day === 0) {
                    spacing = (6 * 14.28);
                } else {
                    spacing = ((day - 1) * 14.28);
                }
            }

            let disponible;

            let test = ("0" + i).slice(-2) + "-" + ("0" + (this.state.currentMonth+1)).slice(-2) + "-" + this.state.currentYear;
            if(this.state.calendar.indexOf(test) === -1){
                disponible = true;
            }else{
                disponible = false;
            }
            days.push(
                <div className={(!disponible ? "vcal-date vcal-date--disabled": "vcal-date")} key={i} style={{marginLeft: spacing + '%'}}>
                    <span className={(!disponible ? "red": "")}>{i}</span>
                </div>
            )
        }

        return days;
    }

    changeMonth(number) {
        var month = this.state.currentMonth + number;
        var year = this.state.currentYear;

        if(month > 11) {
            month = month - 12;
            year = year + 1;
        }

        if(month < 0) {
            month = month + 12;
            year = year - 1;
        }

        this.setState({
            currentMonth: month,
            currentYear: year
        });
    }

    render() {
        return(
            <div className="Mcontainer">
                <h2 className="text-center">Disponibilités</h2>
                <br />
                <div id="v-cal">
                    <div className="vcal-header">
                        <button className="vcal-btn" data-calendar-toggle="previous" onClick={() => this.changeMonth(-1)}>
                            <svg height="24" version="1.1" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"></path>
                            </svg>
                        </button>

                        <div className="vcal-header__label" data-calendar-label="month">
                            {months[this.state.currentMonth] + ' ' + this.state.currentYear}
                        </div>

                        <button className="vcal-btn" data-calendar-toggle="next" onClick={() => this.changeMonth(1)}>
                            <svg height="24" version="1.1" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z"></path>
                            </svg>
                        </button>
                    </div>

                    <div className="vcal-week">
                        <span>Lun</span> <span>Mar</span><span>Mer</span> <span>Jeu</span> <span>Ven</span> <span>Sam</span> <span>Dim</span>
                    </div>
                    <div className="vcal-body" data-calendar-area="month">
                        {
                            this.generateDays()
                        }
                    </div>

                    
                </div>
            </div>
        );
    }
}

export default Calendar;