import React from 'react';
import './Localisation.css';
import Plan from '../../assets/images/plan.jpg';

function Localisation() {
    return(
        <div className='container-fluid'>
            <h2 className="text-center">Localisation</h2>
            <br />
            <ul className="ml-5">
                <li>En voiture: Autoroute 143 jusqu'à Albertville, puis N90 jusqu'à Bourg St Maurice (via Moutiers), prendre la D902</li>
                <br />
                <li>En train : La gare la plus proche est la gare de Bourg St Maurice (à 30km) Transfert possible en car ou navette depuis la gare de Bourg St Maurice jusqu'à Tignes le Lac. </li>
                <br />
                <li>En avion : Les aéroports les plus proches de Tignes le Lac sont l'aéroport de Genève Cointrin, l'apéroport de Lyon Saint-Exupéry, l'Aéroport de Chambéry</li>
            </ul>
            <div className="container text-center">
                <img src={Plan} alt="plan" width="100%"/>
            </div>
            
        </div>
    );
}

export default Localisation;