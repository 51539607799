import React from 'react';

import './Description.css';

function Description() {
    return(
        <div className="container-fluid">
            <h2 className="text-center">Description</h2>
            <br />
            <ul className="ml-5">
                <li className="listItem">Au pied des pistes. Téléski à 20m</li>
                <li className="listItem">Séjour 30m2 avec cuisine américaine</li>
                <li className="listItem">Séjour et balcon sud. Magnifique vue lac et glacier</li>
                <li className="listItem">Suite parentale - un lit 160*200 - salle de douche, lavabo, wc</li>
                <li className="listItem">Chambre enfants 1 - deux lits jumeaux 90*190 (possibilité lit double) et 1 lit tiroir 80*180</li>
                <li className="listItem">Chambre enfants 2 - lits superposés 90*190 et 1 lit tiroir 80*180</li>
                <li className="listItem">Salle de douche indépendante avec wc</li>
                <li className="listItem">Grande entrée avec rangements</li>
                <li className="listItem">Casier à skis, Ascenseur</li>
                <li className="listItem">Lave-vaisselle, lave-linge, plaque à induction, four, micro-ondes, Nespresso</li>
                <li className="listItem">Internet wifi, TV HD, Lecteur DvD, chaine Hi-Fi</li>
                <li className="listItem">Cours de skis à 200m</li>
                <li className="listItem">Lits faits à l'arrivée et serviettes fournies</li>
                <li className="listItem">Caution 1300€ - caution ménage 150€</li>
                <li className="listItem">Paiement 40% à la réservation, 60% 45 jours avant le début du séjour</li>
                <li className="listItem">Appartement pour 6 adultes et 2 enfants maximum</li>
                <li className="listItem">Taxe de séjour 2,48€/jour/personne</li>
                <li className="listItem">Non fumeur, animaux non admis</li>
            </ul>
        </div>
    )
}

export default Description;